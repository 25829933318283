import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Currency } from './currency.model';
import { ResponseCrypto } from './dataCrypto.model';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    private key: string = 'b767b6454d3aa9d2aefb';
    constructor(private httpClient: HttpClient) {}

    /* To get the current currency exchange rates based on the base provided for the given 'toCurrency' */
    getCurrency(fromCurrency: string, toCurrency: string): Observable<Currency> {

        let headers = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Methods', '*');
        headers = headers.append('Access-Control-Allow-Origin', '*');

        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': '*'
          }
          
          const requestOptions = {                                                                                                                                                                                 
            headers: new Headers(headerDict), 
          };

          let url = 'https://api.ratesapi.io/api/latest?base=' + fromCurrency + '&symbols=' + toCurrency;

        return this.httpClient.get<Currency>(url, {headers:headers });
    }


    getCurrencyApp(){
        return this.httpClient.get<any>('https://free.currconv.com/api/v7/convert?q=USD_EUR&compact=ultra&apiKey='+this.key);
    }

    getData(){

        
        let headers = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Methods', '*');
        headers = headers.append('Access-Control-Allow-Origin', '*');

        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': '*'
          }
          
          const requestOptions = {                                                                                                                                                                                 
            headers: new Headers(headerDict), 
          };
        return this.httpClient.get<ResponseCrypto>('https://crypto.com/fe-ex-api/market-data/v2/public/get-ticker',{headers: headers} );
    }
}